import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { ReactComponent as Logo } from "../svg/newlogo.svg";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`,
    import.meta.url,
).toString();



const JustPdfViewer4: React.FC<any> = (props) => {
    const [numPages, setNumPages] = useState<number>();
    // 초기 페이지 번호 (지금은 3으로 되어 있으나, 필요 시 변경 가능)
    const [pageNumber, setPageNumber] = useState<number>(3);

    // 화면의 width/height를 가져오는 훅
    const { width } = useWindowDimensions();

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
        setNumPages(numPages);
    }

    return (
        <div style={{
            display: "flex",         // 가운데 정렬을 위한 flex 컨테이너
            justifyContent: "center" // 수평 가운데 정렬
        }}>
            <div style={{
                width: "100%",
                maxWidth: "1000px",   // 최대 1000px까지
            }}>
                <Document
                    file="/pdf/start.pdf"
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {Array.from({ length: numPages ?? 0 }, (el, index) => (
                        <div key={`wrapper_${index + 1}`} style={{ margin: "0 auto" }}>
                            <Page
                                loading={<span style={{ fontSize: "200px" }}>loading</span>}
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                // 여기서 화면 width와 1000 중 더 작은 값을 사용
                                width={Math.min(width, 1000)}
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                            />
                            <div style={{ height: "50px" }} />
                        </div>
                    ))}
                </Document>
            </div>
        </div>
    );
};

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState<{
        width: number;
        height: number;
    }>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        // 필요하면 다시 활성화하여 리사이즈 시마다 state 갱신
        // function handleResize() {
        //     setWindowDimensions({
        //         width: window.innerWidth,
        //         height: window.innerHeight,
        //     });
        // }
        // window.addEventListener("resize", handleResize);
        // return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

export default JustPdfViewer4;