export const list = [
    {
        title : "전체전경1",
        description : "24시간 작동되는 환기 시설로 많은 학생들이 공기가 정말 쾌적하다고 얘기합니다.",
        src : "img/newinterior/02BL7589_1.webp"
    },
    {
        title : "전체전경2",
        description : "항상 최적의 깔끔함을 유지하는데 적합한 자재들을 사용하였고 충분한 환기 시설을 통해 쾌적함을 유지합니다.",
        src : "img/newinterior/02BL7841_1.webp"
    },
    {
        title : "자습실전경",
        description : "24시간 최고급 환기 설비, 공기청정기, 백색소음기가 작동되고 있어 넓은 자습실에서 쾌적하게 공부할 수 있습니다.",
        src : "img/newinterior/02BL7643_1.webp"
    },
    {
        title : "교실형구획",
        description : "학생들이 공부에 집중할 수 있도록 적당한 시선 차단과 동시에 개방감을 줄 수 있는 책상으로 구성되어 있습니다.",
        src : "img/newinterior/02BL7616_1.webp"
    },
    {
        title : "교실형책상",
        description : "최고급 퍼시스 책상과 의자로 구성되어 있으며 각 책상마다 잠금장치와 3구 콘센트가 설치되어 있습니다.",
        src : "img/newinterior/02BL7949_1.webp"
    },
    {
        title : "칸막이구획",
        description : "등 뒤 공간을 넓게 사용하고 싶은 학생들을 위한 공간으로 칸막이 책상 이용 학생 전부에게는 대형 사물함이 제공됩니다.",
        src : "img/newinterior/02BL7757_1.webp"
    },
    {
        title : "칸막이책상",
        description : "최고급 시디즈 책상과 시디즈 의자로 구성되어 있으며 밝기 조절이 가능한 스탠드가 설치되어 있습니다.",
        src : "img/newinterior/02BL8045_1.webp"
    },
    {
        title : "휴게실",
        description : "24시간 환기시설이 작동하는 깔끔하고 쾌적한 넓은 휴게실에서 식사와 편안한 공부를 할 수 있습니다.",
        src : "img/newinterior/02BL7823_1.webp"
    },
    {
        title : "상담실전경",
        description : "자연광이 들어오는 넓고 쾌적한 상담실에서 담임 멘토와 매주 상담을 진행합니다.",
        src : "img/newinterior/02BL7844_1.webp"
    },
    {
        title : "상담실",
        description : "넓은 공간에 데스커 책상과 시디즈 의자로 구성되어 있고 칠판이 있어 질의응답에 용이합니다.",
        src : "img/newinterior/02BL7886_1.webp"
    },
    // {
    //     title : "백색소음기",
    //     description : "백색소음기 음량은 학생들이 가장 집중을 잘 할 수 있는 적당한 크기로 조절하고 있습니다.",
    //     src : "img/interiore/whitenoise.webp"
    // },
    // {
    //     title : "시스템에어컨",
    //     description : "모든 에어컨에 윈드바이저가 설치되어 있어 어떤 자리의 학생에게도 직접 바람이 가지 않습니다.",
    //     src : "img/interiore/aircondition.webp"
    // },
    // {
    //     title : "온습도측정기",
    //     description : "열람실의 각 위치에 온습도 측정기가 설치되어 있어 매교시마다 온습도를 체크하고 공부에 가장 최적화된 온습도로 조정합니다.",
    //     src : "img/interiore/temperature5.webp"
    // },
    // {
    //     title : "와이파이",
    //     description : "IPTIME 공유기 중 최고가 모델을 사용하고 와이파이 동시 접속자 수가 많아도 원활하게 인강 수강 가능합니다.",
    //     src : "img/interiore/wifi.webp"
    // },
    // {
    //     title : "스탠딩테이블",
    //     description : "휴게실에는 스탠딩테이블이 설치되어 있어 공부하다 졸린 학생들이 잠을 깨고 공부할 수 있습니다.",
    //     src : "img/interiore/standing.webp"
    // },
    // {
    //     title : "프린터",
    //     description : "속도가 빠른 사무형 프린터가 설치되어 있어 학생들은 언제든 프린트를 빠르게 할 수 있습니다.",
    //     src : "img/interiore/printer.webp"
    // },
    // {
    //     title : "사물함",
    //     description : "크기가 큰 대형 사물함이 설치되어 있고 모든 학생들에게 한 개의 사물함이 제공됩니다.",
    //     src : "img/interiore/cabinet5.webp"
    // },
    // {
    //     title : "콘센트",
    //     description : "모든 휴게실의 테이블에는 콘센트가 설치되어 있어 장시간의 인강 수강도 문제가 없습니다.",
    //     src : "img/01_1.webp"
    // },
    {
        title : "데스크",
        description : "생활관리 사감이 상주하면서 학생들의 출석체크 및 생활관리를 하는 데스크입니다.",
        src : "img/newinterior/02BL7913_1.webp"
    }
];

export const list2 = [
    {
        title : "전체전경1",
        description : "24시간 환기 설비가 가동되고 있고\n전면 유리창이 있는 넓은 휴게실에서 편하게 식사할 수 있습니다.",
        src : "img/daechiinterior/J2V_2023.webp"
    },
    {
        title : "자습실전경1",
        description : "24시간 최고급 환기 설비, 공기청정기, 백색소음기가 작동되고 있어\n넓은 자습실에서 쾌적하게 공부할 수 있습니다.",
        src : "img/daechiinterior/J2V_2032.webp"
    },
    {
        title : "자습실전경2",
        description : "자습실 내에 생활관리사감이 상주하고 있으며,\n 최고급 퍼시스 책걸상으로 구성되어 있습니다.",
        src : "img/daechiinterior/J2V_2053.webp"
    },
    {
        title : "자습실전경3",
        description : "노출형 천장 대형 자습실로 최고의 쾌적함을 느낄 수 있으며,\n 자동 창문 개폐기를 통해 정해진 시간에 환기를 진행합니다.",
        src : "img/daechiinterior/J2V_2134.webp"
    },
    {
        title : "개별콘센트",
        description : "모든 각 책상에는 고급스러운 퍼시스 3구 콘센트가\n설치되어 있습니다.",
        src : "img/daechiinterior/J2V_2111.webp"
    },
    {
        title : "휴게실",
        description : "넓고 쾌적한 휴게실에서 맛잇는 식사를 즐기고\n 편안하게 공부할 수 있습니다.",
        src : "img/daechiinterior/J2V_2015.webp"
    },

    {
        title : "데스크",
        description : "메인 출입구에서 얼굴 인식과 턴게이트를 통해\n단 한명의 오차도 없이 출석체크를 진행하고 있습니다.",
        src : "img/daechiinterior/J2V_1972.webp"
    },
    {
        title : "휴대폰함",
        description : "모든 학생들은 출입 전에\n잠금장치가 있는 휴대폰함에 휴대폰을 제출해야 합니다.",
        src : "img/daechiinterior/J2V_2155.webp"
    },
    {
        title : "환기장치",
        description : "모든 자습실 공간과 휴게실에는\n24시 작동되는 환기설비가 있어 신선한 공기를 유지합니다.",
        src : "img/daechiinterior/J2V_2149.webp"
    },
    // {
    //     title : "백색소음기",
    //     description : "백색소음기 음량은 학생들이 가장 집중을 잘 할 수 있는 적당한 크기로 조절하고 있습니다.",
    //     src : "img/interiore/whitenoise.webp"
    // },
    // {
    //     title : "시스템에어컨",
    //     description : "모든 에어컨에 윈드바이저가 설치되어 있어 어떤 자리의 학생에게도 직접 바람이 가지 않습니다.",
    //     src : "img/interiore/aircondition.webp"
    // },
    // {
    //     title : "온습도측정기",
    //     description : "열람실의 각 위치에 온습도 측정기가 설치되어 있어 매교시마다 온습도를 체크하고 공부에 가장 최적화된 온습도로 조정합니다.",
    //     src : "img/interiore/temperature5.webp"
    // },
    // {
    //     title : "와이파이",
    //     description : "IPTIME 공유기 중 최고가 모델을 사용하고 와이파이 동시 접속자 수가 많아도 원활하게 인강 수강 가능합니다.",
    //     src : "img/interiore/wifi.webp"
    // },
    // {
    //     title : "스탠딩테이블",
    //     description : "휴게실에는 스탠딩테이블이 설치되어 있어 공부하다 졸린 학생들이 잠을 깨고 공부할 수 있습니다.",
    //     src : "img/interiore/standing.webp"
    // },
    // {
    //     title : "프린터",
    //     description : "속도가 빠른 사무형 프린터가 설치되어 있어 학생들은 언제든 프린트를 빠르게 할 수 있습니다.",
    //     src : "img/interiore/printer.webp"
    // },
    // {
    //     title : "사물함",
    //     description : "크기가 큰 대형 사물함이 설치되어 있고 모든 학생들에게 한 개의 사물함이 제공됩니다.",
    //     src : "img/interiore/cabinet5.webp"
    // },
    // {
    //     title : "콘센트",
    //     description : "모든 휴게실의 테이블에는 콘센트가 설치되어 있어 장시간의 인강 수강도 문제가 없습니다.",
    //     src : "img/01_1.webp"
    // },
    {
        title : "상담실",
        description : "넓고 대형 모니터가 설치된 상담실이며\n이 곳에서 매주 담임멘토와의 상담이 이루어집니다.",
        src : "img/daechiinterior/J2V_1940.webp"
    }
];

export const list3 = [
    {
        title : "데스크",
        description : "턴게이트를 통한 정확한 출입체크와 편리한 안면인식기를 통해 출입 할 수 있습니다.",
        src : "img/newinterior/241228_004.webp"
    },
    {
        title : "데스크2",
        description : "데스크 선생님이 항시 부정 출입 및 외출을 체크합니다.",
        src : "img/newinterior/241228_014.webp"
    },
    {
        title : "휴게실",
        description : "넓고 채광이 좋은 휴게실에서 맛있는 식사를 즐기고 편안하게 공부할 수 있습니다.",
        src : "img/newinterior/241228_025.webp"
    },
    {
        title : "휴게실 뷰",
        description : "휴게실에서는 넓고 쾌적한 뷰를 볼 수 있어 공부하다 힘들때 휴식을 취할 수 있습니다.",
        src : "img/newinterior/241228_046.webp"
    },
    {
        title : "사감 선생님",
        description : "자습실 입구에는 사감 선생님과 항시 자습관 분위기를 관리하고 계십니다.",
        src : "img/newinterior/241228_057.webp"
    },
    {
        title : "자습실1",
        description : "개인별 큰 책상과 넓은 공간으로 쾌적하게 공부할 수 있습니다.",
        src : "img/newinterior/241228_062.webp"
    },
    {
        title : "자습실2",
        description : "자습실의 모든 창문은 자동으로 개폐되며 최첨단 환기 시스템이 설치되어 있습니다.",
        src : "img/newinterior/241228_076.webp"
    },
    {
        title : "자습실3",
        description : "자습실 책상에 칸막이가 설치되어 있어 본인 공부에 집중할 수 있습니다.",
        src : "img/newinterior/241228_080.webp"
    },
    {
        title : "전체 전경",
        description : "높은 층고에 넓은 공간으로 공부에 최적화된 환경을 제공합니다.",
        src : "img/newinterior/241228_093.webp"
    },
    {
        title : "상담실",
        description : "넓은 상담실에서 학생의 상황에 맞게 매칭된 담임멘토와 매주 상담을 진행합니다.",
        src : "img/newinterior/241228_023.webp"
    }
]

export const list4 = [
    {
        title : "자습실1",
        description : "사감 선생님이 항시 상주하며 모두가 열심히 공부하는 환경을 제공합니다",
        src : "img/newinterior/bundang/_J2V5266_.webp"
    },
    {
        title : "자습실2",
        description : "충분한 수납공간으로 많은 책도 충분히 수납할 수 있습니다.",
        src : "img/newinterior/bundang/_J2V5281.webp"
    },
    {
        title : "자습실3",
        description : "넓은 자습실에서 서로 경쟁하며 열심히 공부하는 모습을 볼 수 있습니다.",
        src : "img/newinterior/bundang/_J2V5287.webp"
    },
    {
        title : "자습실4",
        description : "자습실의 모든 창문은 자동 개폐 장치가 설치되어 하루도 빠짐없이 환기가 이루어집니다.",
        src : "img/newinterior/bundang/_J2V5293.webp"
    },
    {
        title : "자습실5",
        description : "분당 최대 규모 자습실로 많은 학생들이 한 곳에 모여 열심히 공부하는 환경을 만듭니다",
        src : "img/newinterior/bundang/_J2V5299.webp"
    },
    {
        title : "자습실6",
        description : "자습실에는 항시 두 명의 사감 선생님이 상주하며 엄격한 분위기를 유지합니다.",
        src : "img/newinterior/bundang/_J2V5302.webp"
    },
    {
        title : "자습실7",
        description : "대성 더프, 평가원 등 모의고사를 실제 시험과 같은 환경에서 치를 수 있습니다.",
        src : "img/newinterior/bundang/_J2V5332.webp"
    },
    {
        title : "휴게실1",
        description : "넓은 휴게실에서 맛있는 식사를 즐기고 편안하게 공부할 수 있습니다.",
        src : "img/newinterior/bundang/_J2V5372.webp"
    },
    {
        title : "휴게실2",
        description : "휴게실의 모든 책상에는 콘센트가 설치되어 있어 항상 충전이 가능합니다.",
        src : "img/newinterior/bundang/_J2V5402.webp"
    },
    {
        title : "턴게이트",
        description : "학원 메인 출입구에는 턴게이트가 설치되어 있어 정확한 출석체크가 이루어집니다.",
        src : "img/newinterior/bundang/_J2V5450.webp"
    },
    {
        title : "상담실",
        description : "넓은 상담실에서 매주 담임멘토와 40분씩 상담을 진행합니다.",
        src : "img/newinterior/bundang/_J2V5471.webp"
    },
    {
        title : "데스크",
        description : "모든 학생들은 등원 시 데스크에 핸드폰을 제출해야 합니다.",
        src : "img/newinterior/bundang/_J2V5509_.webp"
    },
    {
        title : "화장실1",
        description : "깔끔한 화장실에 냉난방기 및 핸드타올이 구비되어 있습니다.",
        src : "img/newinterior/bundang/toilet1.webp"
    },
    {
        title : "화장실2",
        description : "공부하는 학생들을 위해 항상 청결한 화장실을 유지합니다.",
        src : "img/newinterior/bundang/toilet2.webp"
    },
]

export const list4forlocation = [
    {
        title : "자습실1",
        description : "사감 선생님이 항시 상주하며 모두가 열심히 공부하는 환경을 제공합니다",
        src : "img/newinterior/bundang/_J2V5266_.webp"
    },
    {
        title : "자습실2",
        description : "충분한 수납공간으로 많은 책도 충분히 수납할 수 있습니다.",
        src : "img/newinterior/bundang/_J2V5281.webp"
    },
    {
        title : "자습실3",
        description : "넓은 자습실에서 서로 경쟁하며 열심히 공부하는 모습을 볼 수 있습니다.",
        src : "img/newinterior/bundang/_J2V5287.webp"
    },
    {
        title : "자습실4",
        description : "자습실의 모든 창문은 자동 개폐 장치가 설치되어 하루도 빠짐없이 환기가 이루어집니다.",
        src : "img/newinterior/bundang/_J2V5293.webp"
    },
    {
        title : "자습실5",
        description : "더프, 평가원 등 모의고사를 실제 시험과 같은 환경에서 치를 수 있습니다.",
        src : "img/newinterior/bundang/_J2V5332.webp"
    },
    {
        title : "휴게실1",
        description : "넓은 휴게실에서 맛있는 식사를 즐기고 편안하게 공부할 수 있습니다.",
        src : "img/newinterior/bundang/_J2V5372.webp"
    },
    {
        title : "휴게실2",
        description : "휴게실의 모든 책상에는 콘센트가 설치되어 있어 항상 충전이 가능합니다.",
        src : "img/newinterior/bundang/_J2V5402.webp"
    },
    {
        title : "턴게이트",
        description : "메인 출입구에는 턴게이트가 설치되어 있어 정확한 출석체크가 이루어집니다.",
        src : "img/newinterior/bundang/_J2V5450.webp"
    },
    {
        title : "상담실",
        description : "넓은 상담실에서 매주 담임멘토와 40분씩 상담을 진행합니다.",
        src : "img/newinterior/bundang/_J2V5471.webp"
    },
    {
        title : "데스크",
        description : "모든 학생들은 등원 시 데스크에 핸드폰을 제출해야 합니다.",
        src : "img/newinterior/bundang/_J2V5509_.webp"
    },
    {
        title : "화장실1",
        description : "깔끔한 화장실에 냉난방기 및 핸드타올이 구비되어 있습니다.",
        src : "img/newinterior/bundang/toilet1.webp"
    },
    {
        title : "화장실2",
        description : "공부하는 학생들을 위해 항상 청결한 화장실을 유지합니다.",
        src : "img/newinterior/bundang/toilet2.webp"
    },
]

