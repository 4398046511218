import React, { useState, useEffect, useRef } from "react";
import smoothscroll from "smoothscroll-polyfill";

import HeaderTwo from "../components/header2";
import styles from "../styles/teachers.module.css";
import { ReactComponent as RightChevronSvg } from '../svg/chevron-right-thin.svg';
import { ReactComponent as HouseSvg } from '../svg/house-thin.svg';
import { ReactComponent as RightChevronCircleSvg } from "../svg/chevronrightcircle.svg";
import { ReactComponent as BookSvg } from "../svg/book.svg";
import { ReactComponent as BookSvg2 } from "../svg/auto_stories.svg";
import list from "../data/teacherslist"
import Footer from "../components/footer";
import SpeedDialComponent from "../control/speeddial";

import ReactGa from "react-ga4";
import Vimeo from "@u-wave/react-vimeo";
import { Chip, Option, Select } from "@mui/joy";
import { Group, School } from "@mui/icons-material";



const Teachers2: React.FC<any> = (props) => {
    const [index, setIndex] = useState(1000001);
    const listRef = useRef<any>(null);
    const eachRef = useRef<any>(new Array());

    const [play, setPlay] = useState(true);
    const [pause, setPause] = useState(false);
    const scrollRef = useRef<any>(null);

    const [selectedLocation, setSelectedLocation] = useState("gangnam");
    const [teachersList, setTeachersList] = useState<any>([]);

    //ga event------------------------------------------------
    useEffect(() => {
        // ReactGa.event({
        //     category: "view",
        //     action: "teacherspageview"
        // })

        ReactGa.send({
            hitType: "pageview",
            page_title: "teachers"
        });

    }, []);
    //--------------------------------------------------------

    const mobileChange = (id: number, eachIndex : number) => {
        setIndex(id);

        console.log(eachRef.current);
        const targetScroll = eachRef.current[eachIndex].getBoundingClientRect().x;

        console.log(targetScroll);
        console.log(listRef.current.scrollLeft);

        listRef.current.scrollTo({ left: targetScroll + listRef.current.scrollLeft - 120, behavior: "smooth" });
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        smoothscroll.polyfill();
    }, [])

    const moveTop = () => {
        window.scrollTo({ top: 258, behavior: "smooth" });
    }

    useEffect(() => {

        getTeachersInfo();

    }, []);

    const getTeachersInfo = async () => {

        try {

            const response = await fetch("https://suneungsunbae.com/api/upload/mentorDescriptionInfoTotal");

            const result = await response.json();

            console.log(result);

            const data = result.data;

            const list2: any = [];

            data.forEach((eachData: any) => {

                const oneData: any = {};
                oneData.id = eachData.id;
                oneData.locationList = eachData.locationList;

                const inputs = eachData.totalInputList;

                oneData.img = `/img/university/${inputs[5].value}.svg`;
                oneData.isUniversity = true;
                oneData.subject = inputs[0].value;
                oneData.subTitle = inputs[1].value;
                oneData.name = inputs[2].value;
                oneData.teacherDescription = inputs[3].multi[0].value;
                if (inputs[3].multi[1].value && (inputs[3].multi[1].value !== 0) && (inputs[3].multi[1].value !== "0")) {
                    oneData.teacherDescription += "\n" + inputs[3].multi[1].value;
                }else{
                    oneData.teacherDescription += "\n\u200B";
                }
                oneData.koreanTeacher = [];
                inputs[4].multi[0].multi.forEach((eachTeacher: any) => {

                    if(eachTeacher.value === 0 || eachTeacher.value === "0"){
                        eachTeacher.value = "";
                    }

                    if (eachTeacher.value) {
                        oneData.koreanTeacher.push(eachTeacher.value);
                    }
                });
                oneData.mathTeacher = [];
                inputs[4].multi[1].multi.forEach((eachTeacher: any) => {
                    if(eachTeacher.value === 0 || eachTeacher.value === "0"){
                        eachTeacher.value = "";
                    }

                    if (eachTeacher.value) {
                        oneData.mathTeacher.push(eachTeacher.value);
                    }
                });
                oneData.englishTeacher = [];
                inputs[4].multi[2].multi.forEach((eachTeacher: any) => {
                    if(eachTeacher.value === 0 || eachTeacher.value === "0"){
                        eachTeacher.value = "";
                    }

                    if (eachTeacher.value) {
                        oneData.englishTeacher.push(eachTeacher.value);
                    }
                });
                oneData.scienceTeacher = [];
                inputs[4].multi[3].multi.forEach((eachTeacher: any) => {
                    if(eachTeacher.value === 0 || eachTeacher.value === "0"){
                        eachTeacher.value = "";
                    }

                    if (eachTeacher.value) {
                        oneData.scienceTeacher.push(eachTeacher.value);
                    }
                });

                //inputs[index].title에 * 있으면 지움
                inputs.forEach((eachInput: any) => {
                    if (eachInput.title.includes("*")) {
                        eachInput.title = eachInput.title.replace("*", "");
                    }
                });

                oneData.firstQuestion = inputs[6].title;
                oneData.firstAnswer = inputs[6].value;

                oneData.secondQuestion = inputs[7].title;
                oneData.secondAnswer = inputs[7].value;

                oneData.thirdQuestion = inputs[8].title;
                oneData.thirdAnswer = inputs[8].value;

                oneData.fourthQuestion = inputs[9].title;
                oneData.fourthAnswer = inputs[9].value;

                oneData.fifthQuestion = inputs[10].title;
                oneData.fifthAnswer = inputs[10].value;

                


                console.log(oneData);


                list2.push(oneData);

            });

            const totalList: any = [];

            list.forEach((eachList: any) => {
                eachList.locationList = ["gangnam"]
                eachList.firstQuestion = "수능선배 학생들에게 어떤 담임멘토가 되어주실 건가요?";
                eachList.secondQuestion = "학습지도 시 가장 중요하게 생각하는 것은?";
                eachList.thirdQuestion = "어떤 유형의 학생을 맡았을 때 구체적으로 어떤 도움을 주실 수 있나요?";
                eachList.hasManagerOpinion = true;
            });

            // totalList.push(...list);
            totalList.push(...list2);

            console.log(totalList);

            totalList.sort((a: any, b: any) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.id > b.id) {
                    return 1;
                }
                return 0;
            })

            setTeachersList([...totalList]);

        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {

        if(listRef && listRef.current){
            listRef.current.scrollTo({ left: 0, behavior: "smooth" });
        }

    }, [selectedLocation]);

    useEffect(() => {

        if(teachersList.length === 0){
            return;
        }

        //첫번째 선생님의 id를 index로 설정
        const filteredList = teachersList.filter((eachTeacher: any) => eachTeacher.locationList.includes(selectedLocation));

        if(filteredList.length > 0){
            setIndex(filteredList[0].id);
        }

    }, [selectedLocation, teachersList]);


    return (
        <div>
            <HeaderTwo />
            <div className={styles.voidHeader}>
            </div>
            <div className={styles.subHeader} style={{ backgroundImage: "url(img/newpic/dsc03594_3.webp)" }}>
                <div className={styles.filter}>

                </div>
                <div className={styles.subHeaderText}>
                    <div className={styles.subHeaderTextTitle}>
                        한 명의 담임을 뽑기 위해서
                    </div>
                    <div className={styles.subHeaderTextTitle2}>
                        한 달의 시간이 걸립니다
                    </div>
                    <div className={styles.subHeaderTextSubTitle}>

                    </div>
                </div>
            </div>
            <div className={`${styles.currentMenuViewerDiv} ${styles.onlyPC}`}>
                <div className={styles.currentMenuViewer}>
                    <HouseSvg className={styles.houseSvg} />
                    <RightChevronSvg className={styles.rightChevron} />
                    <div className={styles.currentMenuViewerText_1}>
                        학원소개
                    </div>
                    <RightChevronSvg className={`${styles.rightChevron} ${styles.second}`} />
                    <div className={styles.currentMenuViewerText_2}>
                        담임멘토 소개
                    </div>
                </div>
            </div>
            <div className={`${styles.currentMenuViewerBoarder} ${styles.onlyPC}`}>
            </div>

            <div className={`${styles.titleText} ${styles.onlyPC}`}>
                담임멘토 소개
            </div>
            <div className={`${styles.descriptionText} ${styles.onlyPC}`}>
                수능선배가 자신하는 수능선배 담임멘토들을 소개합니다.
            </div>

            <div style={{ position: "relative", width: "100%" }}>
                <div style={{ display: "flex" }}>

                </div>
                <div ref={scrollRef} className={styles.ceoVideo}>
                    <div className={styles.videoBox}>
                        <div className={styles.vimeoWrapper} style={{
                            paddingTop: 0,
                        }}>
                            <Vimeo
                                responsive
                                width="100%"
                                height="100%"
                                video="https://vimeo.com/815279269"
                                autoplay={play}
                                paused={pause}
                                muted
                                className={styles.player}
                            // onReady={(e: any) => { setTimeout(() => { setPause(true); }, 2000) }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={`${styles.locationListWrapper} ${styles.onlyPC}`}>
                <div className={`${styles.onlyPC} ${styles.locationList}`}>
                    <div className={`${styles.locationListTitle} ${selectedLocation === "gangnam" ? styles.active : ""}`} onClick={() => { setSelectedLocation("gangnam"); }}>
                        강남점
                    </div>
                    <div className={`${styles.locationListTitle} ${selectedLocation === "daechi" ? styles.active : ""}`} onClick={() => { setSelectedLocation("daechi"); }}>
                        대치점
                    </div>
                    <div className={`${styles.locationListTitle} ${selectedLocation === "bundang" ? styles.active : ""}`} onClick={() => { setSelectedLocation("bundang"); }}>
                        분당점
                    </div>
                    <div className={`${styles.locationListTitle} ${selectedLocation === "songpa" ? styles.active : ""}`} onClick={() => { setSelectedLocation("songpa"); }}>
                        송파점
                    </div>
                </div>
            </div>

            <div className={`${styles.mobileLocationListWrapper} ${styles.onlymobile}`}>
                <Select
                value={selectedLocation}
                onChange={(e : any, newValue : any) => { 
                    setSelectedLocation(newValue); }}
                startDecorator={<Group />}
                variant="soft"
                endDecorator={
                    <Chip size="sm" color="primary" variant="soft">
                        {
                            teachersList.filter((eachTeacher: any) => eachTeacher.locationList.includes(selectedLocation)).length
                        }
                    </Chip>
                }
                >
                    <Option value="gangnam">강남점 담임멘토 리스트</Option>
                    <Option value="daechi">대치점 담임멘토 리스트</Option>
                    <Option value="bundang">분당점 담임멘토 리스트</Option>
                    <Option value="songpa">송파점 담임멘토 리스트</Option>
                </Select>
            </div>

            <div ref={listRef} className={`${styles.mobileTitleList} ${styles.onlymobile}`}>
                {
                (teachersList.length > 0) &&
                teachersList.filter((eachTeacher: any) => eachTeacher.locationList.includes(selectedLocation)).map((eachList: any, indexNumber: number) => {
                    return (
                        <div key={indexNumber} ref={(element) => { eachRef.current[indexNumber] = element }} onClick={() => { mobileChange(eachList.id, indexNumber); }} className={`${styles.mobileEachListTitle} ${eachList.id === index ? styles.active : ""}`}>
                            {eachList.name.split(" ")[0]}
                        </div>
                    );
                })}
            </div>

            {/* <div className={`${styles.teachersList} ${styles.onlyPC}`}>
                {list.map((eachTeacher: any, indexNumber: number) => {
                    return (
                        <div key={eachTeacher.name + indexNumber} onClick={(e) => { setIndex(indexNumber); }} className={`${styles.eachTeacherNameDiv} ${indexNumber === index ? styles.active : ""}`}>
                            <div className={`${styles.eachTeacherName} ${indexNumber === index ? styles.active : ""}`}>
                                {eachTeacher.name}
                            </div>
                            <div className={`${styles.eachTeacherSchool} ${indexNumber === index ? "" : styles.none} `}>
                                ({eachTeacher.school})
                            </div>
                        </div>
                    );
                })}
            </div> */}

            <div className={`${styles.teacherList2} ${styles.onlyPC}`}>
                {
                    (teachersList.length > 0) &&
                    teachersList.filter((eachTeacher: any) => eachTeacher.locationList.includes(selectedLocation)).map((eachTeacher: any, indexNumber: number) => {
                        return (
                            <div key={eachTeacher.name + indexNumber} className={`${styles.eachTeacherNameDiv2} ${eachTeacher.id === index ? styles.active : ""}`} onClick={(e) => { setIndex(eachTeacher.id); }}>
                                <div className={styles.eachTeacherName2}>
                                    {eachTeacher.name}
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            {
                (teachersList.length > 0) &&
                teachersList.filter((eachTeacher: any) => eachTeacher.id === index).map((teacher : any, index : number) => (
                <div key={index}>
                    {/* 프로필 영역 */}
                    <div className={styles.teacherProfileDiv}>
                        <div className={styles.teacherProfile}>
                            {/* 모바일 전용 과목/부제목 */}
                            <div className={`${styles.mobileTeacherSubject} ${styles.onlymobile}`}>
                                <div className={styles.dashDiv_1}></div>
                                <div className={styles.mobileTeacherSubjectText}>{teacher.subject}</div>
                                <div className={styles.dashDiv_2}></div>
                            </div>
                            <div className={`${styles.mobileTeacherSubtitle} ${styles.onlymobile}`}>
                                <div className={styles.mobileTeacherSubtitleText}>{teacher.subTitle}</div>
                            </div>

                            {/* 강사 이미지 */}
                            <div
                                className={`${styles.teacherImg} ${teacher.isUniversity ? styles.university : ""} ${teacher.img.includes("고려대학교") ? styles.korea : ""} `}
                                style={{ backgroundImage: `url(${teacher.img})` }}
                            />

                            {/* 프로필 설명 영역 */}
                            <div className={styles.teacherProfileDescription}>
                                {/* PC 전용 과목/부제목 */}
                                <div className={`${styles.teacherProfileSubject} ${styles.onlyPC}`}>
                                    <div>{teacher.subject}</div>
                                    <div className={styles.dashDiv}></div>
                                </div>
                                <div className={`${styles.teacherSubTitle} ${styles.onlyPC}`}>
                                    {teacher.subTitle}
                                </div>

                                {/* 이름 및 상세 설명 */}
                                <div className={styles.teacherName}>{teacher.name}</div>
                                <div className={styles.teacherDescription}>{teacher.teacherDescription}</div>

                                {/* 과목별 수강 강사 */}
                                <div className={styles.teacherLecturerTitle}>과목별 수강강사</div>
                                <div className={styles.teacherLecturerListDiv}>
                                    <div className={styles.teacherLecturerSubject}>
                                        <div className={styles.teacherLecturerSubjectTitle}>국어</div>
                                        {teacher.koreanTeacher.map((t : string, i : number) => (
                                            <div key={i} className={styles.eachTeacherMapName}>
                                                {t}
                                            </div>
                                        ))}
                                    </div>
                                    <div className={styles.teacherLecturerSubject}>
                                        <div className={styles.teacherLecturerSubjectTitle}>수학</div>
                                        {teacher.mathTeacher.map((t : string, i : number) => (
                                            <div key={i} className={styles.eachTeacherMapName}>
                                                {t}
                                            </div>
                                        ))}
                                    </div>
                                    <div className={styles.teacherLecturerSubject}>
                                        <div className={styles.teacherLecturerSubjectTitle}>영어</div>
                                        {teacher.englishTeacher.map((t : string, i : number) => (
                                            <div key={i} className={styles.eachTeacherMapName}>
                                                {t}
                                            </div>
                                        ))}
                                    </div>
                                    <div className={styles.teacherLecturerSubject}>
                                        <div className={styles.teacherLecturerSubjectTitle}>탐구</div>
                                        {teacher.scienceTeacher.map((t : string, i : number) => (
                                            <div key={i} className={styles.eachTeacherMapName}>
                                                {t}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 질문 영역 */}
                    <div className={styles.questions}>
                        <div className={styles.questionDiv}>
                            <div className={styles.questionTitle}>
                                <div className={`${styles.questionTitleText} ${styles.onlyPC}`}>
                                    {teacher.firstQuestion}
                                </div>
                                <div className={`${styles.questionTitleText} ${styles.onlymobile}`}>
                                    {teacher.firstQuestion}
                                </div>
                                <div className={`${styles.rightChevronCircle} ${styles.onlyPC}`}>
                                    <RightChevronCircleSvg />
                                </div>
                            </div>
                            <div className={styles.questionDescriptionDiv}>
                                <div className={styles.questionAnswerA}>A</div>
                                <div className={styles.questionAnswer}>{teacher.firstAnswer}</div>
                            </div>
                        </div>

                        <div className={styles.questionDiv}>
                            <div className={styles.questionTitle}>
                                <div className={styles.questionTitleText}>
                                    {teacher.secondQuestion}
                                </div>
                                <div className={`${styles.rightChevronCircle} ${styles.onlyPC}`}>
                                    <RightChevronCircleSvg />
                                </div>
                            </div>
                            <div className={styles.questionDescriptionDiv}>
                                <div className={styles.questionAnswerA}>A</div>
                                <div className={styles.questionAnswer}>{teacher.secondAnswer}</div>
                            </div>
                        </div>

                        <div className={styles.questionDiv}>
                            <div className={styles.questionTitle}>
                                <div className={`${styles.questionTitleText} ${styles.onlyPC}`}>
                                    {teacher.thirdQuestion}
                                </div>
                                <div className={`${styles.questionTitleText} ${styles.onlymobile}`}>
                                    {teacher.thirdQuestion}
                                </div>
                                <div className={`${styles.rightChevronCircle} ${styles.onlyPC}`}>
                                    <RightChevronCircleSvg />
                                </div>
                            </div>
                            <div className={styles.questionDescriptionDiv}>
                                <div className={styles.questionAnswerA}>A</div>
                                <div className={styles.questionAnswer}>{teacher.thirdAnswer}</div>
                            </div>
                        </div>

                        {
                            teacher.fourthQuestion &&
                            <div className={styles.questionDiv}>
                                <div className={styles.questionTitle}>
                                    <div className={styles.questionTitleText}>
                                        {teacher.fourthQuestion}
                                    </div>
                                    <div className={`${styles.rightChevronCircle} ${styles.onlyPC}`}>
                                        <RightChevronCircleSvg />
                                    </div>
                                </div>
                                <div className={styles.questionDescriptionDiv}>
                                    <div className={styles.questionAnswerA}>A</div>
                                    <div className={styles.questionAnswer}>{teacher.fourthAnswer}</div>
                                </div>
                            </div>
                        }
                        {
                            teacher.fifthQuestion &&
                            <div className={styles.questionDiv}>
                                <div className={styles.questionTitle}>
                                    <div className={styles.questionTitleText}>
                                        {teacher.fifthQuestion}
                                    </div>
                                    <div className={`${styles.rightChevronCircle} ${styles.onlyPC}`}>
                                        <RightChevronCircleSvg />
                                    </div>
                                </div>
                                <div className={styles.questionDescriptionDiv}>
                                    <div className={styles.questionAnswerA}>A</div>
                                    <div className={styles.questionAnswer}>{teacher.fifthAnswer}</div>
                                </div>
                            </div>
                        }
                    </div>

                    {/* 면접 결과 영역 */}
                    {
                        teacher.hasManagerOpinion &&
                        <div className={styles.interviewResultDiv}>
                            <div className={styles.interviewResult}>
                                <div className={styles.interviewTitleDiv}>
                                    <div className={styles.interviewTitle}>면접 결과</div>
                                    <div className={`${styles.bookSvg} ${styles.onlyPC}`}>
                                        <BookSvg className={styles.bookSvg} />
                                    </div>
                                    <div className={`${styles.bookSvg} ${styles.onlymobile}`}>
                                        <BookSvg2 className={styles.bookSvg} />
                                    </div>
                                </div>
                                <div className={styles.interviewResultDescription}>
                                    {teacher.interviewResult}
                                </div>
                            </div>
                        </div>
                    }

                    <div className={styles.bottomDiv}></div>
                </div>
            ))}


            {/* <Fab onClick={moveTop}  size="medium" sx={{position : "fixed", bottom : 16, right : 16}} color="primary" aria-label="add">
                <ArrowUpwardIcon />
            </Fab> */}

            <SpeedDialComponent />

            <Footer />


        </div>
    );
}

export default Teachers2;